import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				О нас | О домашних горизонтах
			</title>
			<meta name={"description"} content={"Строительство сообществ, по одному дому за раз - наша приверженность вашему путешествию"} />
			<meta property={"og:title"} content={"О нас | О домашних горизонтах"} />
			<meta property={"og:description"} content={"Строительство сообществ, по одному дому за раз - наша приверженность вашему путешествию"} />
			<meta property={"og:image"} content={"https://swixys.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://swixys.com/img/5725108.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://swixys.com/img/5725108.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://swixys.com/img/5725108.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://swixys.com/img/5725108.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://swixys.com/img/5725108.png"} />
			<meta name={"msapplication-TileImage"} content={"https://swixys.com/img/5725108.png"} />
		</Helmet>
		<Components.Header />
		<Section background="--color-primary" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>

						<Text as="h2" margin="12px 0" font="--headline2" md-font="--headline3">
							О нас
						</Text>
						<Text
							as="h4"
							margin="12px 0"
							font="--base"
							color="--grey"
							letter-spacing="1px"
						>
							В основе деятельности компании Swixys лежит простое, но глубокое убеждение: поиск жилья - это не просто сделка, это поворотный шаг на жизненном пути человека. Мы отправились в это путешествие, чтобы демистифицировать процесс покупки, продажи и аренды жилья, сделав его более доступным, прозрачным и соответствующим мечтам и устремлениям каждого человека. Наша философия сосредоточена на личных связях, понимая, что каждое взаимодействие является строительным блоком в фундаменте процветающего сообщества.
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://swixys.com/img/3.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--lead"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Мы подходим к работе с недвижимостью, сочетая традиции и инновации, уважая непреходящие ценности честности и преданности, и в то же время принимая изменения и вызовы современного мира. Наша команда стремится обеспечить исключительный уровень обслуживания, характеризующийся тщательным вниманием к деталям, оперативностью и неизменным вниманием к потребностям и желаниям наших клиентов.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://swixys.com/img/4.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Что отличает нас от других
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					- Ориентация на клиента: ваши цели - наш приоритет. Мы стремимся понять ваш образ жизни, предпочтения и стремления, чтобы найти идеальный вариант для вас.
					<br />
					<br />
					- Экспертиза и понимание: Наша команда обладает всеми необходимыми знаниями и навыками, чтобы ориентироваться в сложностях рынка недвижимости, обеспечивая принятие обоснованных решений.
					<br />
					<br />
					- Честность и доверие: Мы верим в прозрачность и честность во всех наших делах, создавая основу для доверия с нашими клиентами и партнерами.
					<br />
					<br />
					- Участие в жизни общества: Мы стремимся не просто найти для вас дом, но и интегрировать вас в сообщество, которое соответствует вашим ценностям и образу жизни.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 120px 0" sm-padding="80px 0 90px 0" quarkly-title="Statistics-10">
			<Text
				margin="0px 0px 20px 0px"
				letter-spacing="2px"
				color="--зкшьфкн"
				font="normal 500 20px/1.5 --fontFamily-sansVerdana"
				border-color="#dca654"
			>
				Наше видение будущего
			</Text>
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box min-width="100px" min-height="100px" lg-width="100%">
					<Text margin="0 0px 0px 0px" font="normal 600 26px/1.2 --fontFamily-serifGeorgia" color="#050c32" sm-font="normal 600 30px/1.2 --fontFamily-serifGeorgia">
						Мы смотрим в будущее, и наше видение остается четким: расширять сферу деятельности, затрагивая все больше жизней и сообществ, всегда оставаясь верными нашей миссии - сделать путешествие к поиску идеального дома таким же радостным и насыщенным, как и само место назначения. Мы приглашаем вас присоединиться к нам в этом увлекательном путешествии, изучая возможности и открывая для себя горизонты.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});